import React from 'react'
import { addIntToString, imageNamed } from './helper'
import { Field } from 'formik'


// Front End



//clientScreenTab
export const clientScreenTab = {
    info: 'Info',
    measurements: 'Measurements',
    orders: 'Orders'
}

// All the deparments 
export const departments = {
    Store: 'store',
    Factory: 'factory',
    Vendors: 'vendors',
    Transit: 'transit',
    Clients: 'clients',
    Stocks: 'stocks',
    Reports: 'reports',
    Slides: 'slides',
    Users: 'users',
    Settings: 'settings'
}





export const screen = {

    ProductsOfOrder: "Order Details",
    ProductDetails: "ProductDetails",

    NewOrder: "New Order",
    RecentOrders: "Recent Orders",
    PendingInfo: "Pending Info",

    Search_Store: "Search (Store)",

    FactoryWindow: "Factory Window ",
    SendOrders_Store_Factory: "Send Orders screen (to Factory)",
    RecieveOrders_Store_Factory: "Recieve Orders (from Factory)",

    VendorWindow: "Vendor Window ",
    SendOrders_Store_Vendor: "Send Orders screen (Vendor)",
    RecieveOrders_Store_Vendor: "Recieve Orders (Vendor)",

    ClientWidnow: "Client Window",
    ApproveTrial: "Approve Trial",
    ApproveFinishing: "Approve Alteration",
    ApproveDelivery: "Approve Delivery",
    ApprochingSched: "Approaching Sched.",
    BehindSched: "Behind Sched.",


    OrdersRecieved_Factory: "Accept Orders .F",
    CompleteOrders_Factory: "Complete Orders .F",
    SendOrders_Factory: "Send Orders .F",
    Search_Factory: "Search (Factory)",


    Vendors: "Vendors ",
    OrdersRecieved_Vendor: "Accept Orders ",
    CompleteOrders_Vendor: "Complete Orders ",
    SendOrders_Vendor: "Send Orders ",
    Search_Vendor: "Search ",

    AllClients: "Recent Clients",
    Search_Clients: "Client Search",

    StockTypes: "All Stocks",
    Search_Stocks: "Stock Search",

    Users: "Users",
    Positions: "Positions",
    Agents: "Agents",

    Transit: "Transit",
    Challans: "Challans",
    ScanSendToFactory: "Scan Send To Factory",
    ScanRecieveFromFactory: "Scan Recieve From Factory",
    ScanSendToStore: "Scan Send To Store",
    ScanRecieveFromStore: "Scan Recieve From Store",
    ScanInfo: "Scan Info",

    ClientReports: "Client Reports",
    OrderReports: "Order Reports",
    ProductReports: "Product Reports",
    StockReports: "Stock Reports",
    VendorReports: "Vendor Reports",
    StaffReports: "Staff Reports",

    Slides: "Slides",
    SlideSettings: "Slides Settings",

    Settings: "Settings",
    ProductSettings: "Products Set.",
    TemplateSettings: "Templates Set.",
    UserSettings: "Users Set.",

    LocationSettings: "LocationSettings",

}

export const screenForRoute = (route) => {
    let thisSscreen = ''
    switch ('' + route) {
        case '/store/newOrder': thisSscreen = screen.NewOrder; break;
        case '/store/recentOrders': thisSscreen = screen.RecentOrders; break;
        case '/store/pendingInfo': thisSscreen = screen.PendingInfo; break;
        case '/store/search': thisSscreen = screen.Search_Store; break;

        case '/store/factoryWindow': thisSscreen = screen.FactoryWindow; break;
        case '/store/factoryWindow/sendOrders': thisSscreen = screen.SendOrders_Store_Factory; break;
        case '/store/factoryWindow/recieveOrders': thisSscreen = screen.RecieveOrders_Store_Factory; break;

        case '/store/vendorWindow': thisSscreen = screen.VendorWindow; break;
        case '/store/vendorWindow/sendOrders': thisSscreen = screen.SendOrders_Store_Vendor; break;
        case '/store/vendorWindow/recieveOrders': thisSscreen = screen.RecieveOrders_Store_Vendor; break;

        case '/store/clientWindow': thisSscreen = screen.ClientWidnow; break;
        case '/store/clientWindow/trial': thisSscreen = screen.ApproveTrial; break;
        case '/store/clientWindow/finishing': thisSscreen = screen.ApproveFinishing; break;
        case '/store/clientWindow/deliver': thisSscreen = screen.ApproveDelivery; break;
        case '/store/clientWindow/approachingSchedule': thisSscreen = screen.ApprochingSched; break;
        case '/store/clientWindow/behindSchedule': thisSscreen = screen.BehindSched; break;

        case '/factory/confirmOrders': thisSscreen = screen.OrdersRecieved_Factory; break;
        case '/factory/completeOrders': thisSscreen = screen.CompleteOrders_Factory; break;
        case '/factory/sendOrders': thisSscreen = screen.SendOrders_Factory; break;
        case '/factory/search': thisSscreen = screen.Search_Factory; break;

        case '/vendors': thisSscreen = screen.Vendors; break;
        case '/vendors/confirmOrders': thisSscreen = screen.OrdersRecieved_Vendor; break;
        case '/vendors/completeOrders': thisSscreen = screen.CompleteOrders_Vendor; break;
        case '/vendors/sendOrders': thisSscreen = screen.SendOrders_Vendor; break;
        case '/vendors/search': thisSscreen = screen.Search_Vendor; break;

        case '/clients': thisSscreen = screen.AllClients; break;
        case '/clients/search': thisSscreen = screen.Search_Clients; break;

        case '/stocks': thisSscreen = screen.StockTypes; break;
        case '/stocks/search': thisSscreen = screen.Search_Stocks; break;

        case '/users': thisSscreen = screen.Users; break;
        case '/users/positions': thisSscreen = screen.Positions; break;
        case '/users/agents': thisSscreen = screen.Agents; break;

        case '/reports/clients': thisSscreen = screen.ClientReports; break;
        case '/reports/orders': thisSscreen = screen.OrderReports; break;
        case '/reports/products': thisSscreen = screen.ProductReports; break;
        case '/reports/stocks': thisSscreen = screen.StockReports; break;
        case '/reports/vendors': thisSscreen = screen.VendorReports; break;
        case '/reports/staff': thisSscreen = screen.StaffReports; break;

        case '/slides': thisSscreen = screen.Slides; break;
        case '/slides/settings': thisSscreen = screen.SlideSettings; break;

        case '/transit': thisSscreen = screen.Transit; break;
        case '/transit/challans': thisSscreen = screen.Challans; break;
        case '/transit/scanSendToFactory': thisSscreen = screen.ScanSendToFactory; break;
        case '/transit/scanRecieveFromFactory': thisSscreen = screen.ScanRecieveFromFactory; break;
        case '/transit/scanSendToStore': thisSscreen = screen.ScanSendToStore; break;
        case '/transit/scanRecieveFromStore': thisSscreen = screen.ScanRecieveFromStore; break;
        case '/transit/scanInfo': thisSscreen = screen.ScanInfo; break;

        case '/settings': thisSscreen = screen.Settings; break;
        case '/settings/products': thisSscreen = screen.ProductSettings; break;
        case '/settings/templates': thisSscreen = screen.TemplateSettings; break;
        case '/settings/locations': thisSscreen = screen.LocationSettings; break;
        default: break;
    }

    // console.log('Screen is = ' + thisSscreen + '. For  route ' + route)
    return thisSscreen
}

export const screenMenuLink = (thisScreen) => {
    let returnLink = ''
    switch (thisScreen) {
        case screen.ProductsOfOrder: returnLink = ''; console.log('Matched First'); break;
        case screen.ProductDetails: returnLink = ''; break;

        case screen.NewOrder: returnLink = '/store/newOrder'; break;
        case screen.RecentOrders: returnLink = '/store/recentOrders'; break;
        case screen.PendingInfo: returnLink = '/store/pendingInfo'; break;
        case screen.Search_Store: returnLink = '/store/search'; break;

        case screen.FactoryWindow: returnLink = '/store/factoryWindow '; break;
        case screen.SendOrders_Store_Factory: returnLink = '/store/factoryWindow/sendOrders'; break;
        case screen.RecieveOrders_Store_Factory: returnLink = '/store/factoryWindow/recieveOrders'; break;

        case screen.VendorWindow: returnLink = '/store/vendorWindow '; break;
        case screen.SendOrders_Store_Vendor: returnLink = '/store/vendorWindow/sendOrders'; break;
        case screen.RecieveOrders_Store_Vendor: returnLink = '/store/vendorWindow/recieveOrders'; break;

        case screen.ClientWidnow: returnLink = '/store/clientWindow'; break;
        case screen.ApproveTrial: returnLink = '/store/clientWindow/trial'; break;
        case screen.ApproveFinishing: returnLink = '/store/clientWindow/finishing'; break;
        case screen.ApproveDelivery: returnLink = '/store/clientWindow/deliver'; break;
        case screen.ApprochingSched: returnLink = '/store/clientWindow/approachingSchedule'; break;
        case screen.BehindSched: returnLink = '/store/clientWindow/behindSchedule'; break;

        case screen.OrdersRecieved_Factory: returnLink = '/factory/confirmOrders'; break;
        case screen.CompleteOrders_Factory: returnLink = '/factory/completeOrders'; break;
        case screen.SendOrders_Factory: returnLink = '/factory/sendOrders'; break;
        case screen.Search_Factory: returnLink = '/factory/search'; break;


        case screen.Vendors: returnLink = '/vendors'; break;
        case screen.OrdersRecieved_Vendor: returnLink = '/vendors/confirmOrders'; break;
        case screen.CompleteOrders_Vendor: returnLink = '/vendors/completeOrders'; break;
        case screen.SendOrders_Vendor: returnLink = '/vendors/sendOrders'; break;
        case screen.Search_Vendor: returnLink = '/vendors/search'; break;

        case screen.AllClients: returnLink = '/clients'; break;
        case screen.Search_Clients: returnLink = '/clients/search'; break;

        case screen.StockTypes: returnLink = '/stocks'; break;
        case screen.Search_Stocks: returnLink = '/stocks/search'; break;

        case screen.Users: returnLink = '/users'; break;
        case screen.Positions: returnLink = '/users/positions'; break;
        case screen.Agents: returnLink = '/users/agents'; break;

        case screen.ClientReports: returnLink = '/reports/clients'; break;
        case screen.OrderReports: returnLink = '/reports/orders'; break;
        case screen.ProductReports: returnLink = '/reports/products'; break;
        case screen.StockReports: returnLink = '/reports/stocks'; break;
        case screen.VendorReports: returnLink = '/reports/vendors'; break;
        case screen.StaffReports: returnLink = '/reports/staff'; break;

        case screen.Slides: returnLink = '/slides'; break;
        case screen.SlideSettings: returnLink = '/slides/settings'; break;

        case screen.Transit: returnLink = '/transit'; break;
        case screen.Challans: returnLink = '/transit/challans'; break;
        case screen.ScanSendToFactory: returnLink = '/transit/scanSendToFactory'; break;
        case screen.ScanRecieveFromFactory: returnLink = '/transit/scanRecieveFromFactory'; break;
        case screen.ScanSendToStore: returnLink = '/transit/scanSendToStore'; break;
        case screen.ScanRecieveFromStore: returnLink = '/transit/scanRecieveFromStore'; break;
        case screen.ScanInfo: returnLink = '/transit/scanInfo'; break;

        case screen.Settings: returnLink = '/settings'; break;
        case screen.ProductSettings: returnLink = '/settings/products'; break;
        case screen.TemplateSettings: returnLink = '/settings/templates'; break;
        case screen.LocationSettings: returnLink = '/settings/locations'; break;
        default: break;
    }

    // console.log('Incoming Screen = ' + thisScreen)
    // console.log('Sending RETURN LINK = ' + returnLink)

    return returnLink
}




export const departmentForscreen = (scr) => {

    switch (scr) {
        case screen.NewOrder: 
        case screen.RecentOrders: 
        case screen.PendingInfo: 
        case screen.ProcessOrder: case screen.PendingOrders_Store
            : case screen.SendOrders_Store: case screen.RecieveOrders_Store
            : case screen.CustomerDealing: case screen.ApproveTrial: case screen.ApproveDelivery : case screen.ApprochingSched : case screen.BehindSched
            : case screen.ApproveFinishing: case screen.ViewStatus
            : case screen.Search_Store:
            return departments.Store;

        case screen.OrdersRecieved_Factory: case screen.CompleteOrders_Factory: case screen.SendOrders_Factory
            : case screen.Search_Factory:
            return departments.Factory;

        case screen.Vendors: case screen.OrdersRecieved_Vendor: case screen.CompleteOrders_Vendor: case screen.SendOrders_Vendor
            : case screen.Search_Vendor:
            return departments.Vendors;

        case screen.StockTypes: case screen.Search_Stocks:
            return departments.Vendors;

        case screen.Positions: case screen.Users: case screen.Agents: case screen.Craftsmen: case screen.Locations:
            return departments.Users;

        case screen.Challans: case screen.ScanSendToFactory: case screen.ScanSendToStore: case screen.ScanRecieveFromFactory: case screen.ScanRecieveFromStore
            : case screen.ScanInfo:
            return departments.Transit;

        case screen.ClientReports: case screen.OrderReports: case screen.ProductReports: case screen.StockReports:
            return departments.Reports;

        case screen.ProductSettings: case screen.TemplateSettings: case screen.LocationSettings:
            return departments.Settings;

        case screen.Slides: case screen.SlideSettings:
            return departments.Slides;

        default: return null;
    }

}



export const pages = {
    Dashboard: 'dashboard',
    Purchases: 'purchases',
    Invoices: 'invoices',
    Payments: 'payments',
    Profile: 'profile',
    Compliance: 'compliance',
    Messages: 'messages',
    Checkout: 'checkout',
    SignIn: 'signIn',
    SignUp: 'signUp',
    MessageLinkToSignIn: 'messageLinkToSignIn',
    ForgotPassword: 'forgotPassword'
}

export const menuButtonActions = {
    SignIn: 'signIn',
    SignUp: 'signUp',
    Logout: 'logout'
}


export const invoicePages = {
    Invoices: 'invoices',
    Payments: 'payments',
    PaymentMethods: 'paymentMethods',
    BillingAddress: 'billingAddress',
    Taxation: 'taxation'
}

export const profilePages = {
    Profile: 'profile',
    // Contacts: 'contacts',
    ChangePassword: 'changePassword'
}

export const checkoutPages = {
    Order: 'order',
    Billing: 'billing',
    Payment: 'payment'
}





// Classes  ___values should be Capitalised


export const picType = {
    CustomerPic: 'CustomerPic',
    ProductPic: 'ProductPic',
    CustomerProfilePic: 'CustomerProfilePic',
    VendorProfilePic: 'VendorProfilePic',
    UserPic: 'UserPic'
}


export const purchaseTypes = {
    Order: 'Order',
    Subscription: 'Subscription',
    AddOn: 'AddOn'
}

export const purchaseStatus = {
    Alloted: 'Alloted',
    Active: 'Active',
    Expired: 'Expired',
    Cancelled: 'Cancelled',
    Pending: 'Pending'
}


export const invoiceTypes = {
    Order: 'Order',
    Subscription: 'Subscription'
}

export const invoiceStatus = {
    Pending: 'Pending',
    Paid: 'Paid'
}


export const paymentStatus = {
    Success: 'Success',
    Failed: 'Failed',
    Processing: 'Processing',
    Refunded: 'Refunded',
    Cancelled: 'Cancelled',
    Unknown: 'Unknown'
}


export const currency = {
    INR: 'INR',
    USD: 'USD'
}


export const paymentMethods = {
    Card: 'Card',
    Account: 'Account',
    NetBanking: 'Net Banking',
    BankTransfer: 'Bank Transfer',
    Paypal: 'Paypal',
    Wallets: 'Wallets',
    Credits: 'Credits'
}

export const paymentPurpose = {
    Pay: 'Pay',
    ChangeCard: 'ChangeCard',
    AddCard: 'AddCard',
    ChangeAccount: 'ChangeAccount',
    AddAccount: 'AddAccount'
}


export const paymentInitiation = {
    Manual: 'Manual',
    Charge: 'Charge'
}

export const complianceTypes = {
    Contract: 'Contract',
    Terms: 'Terms',
    Taxation: 'Taxation',
    Policy: 'Policy',
    Custom: 'Custom'
}

export const complianceStrength = {
    highest: 'Highest',
    medium: 'Medium',
    low: 'Low'
}





/// FROM XCODE ////



export const InfoSource = {
    online: 'online',
    friendsAndFamily: 'friendsAndFamily'
}


export const ProductType = {

    // Diwan Saheb & Vaish
    //    case Pant = "PANT"
    //    case Blazer = "BLAZER"
    //    case Jacket = "JACKET"

    // Karan Moin
    Sherwani: "SHERWANI",
    Suit2piece: "SUIT 2 PIECE",
    Suit3piece: "SUIT 3 PIECE",
    Coat: "COAT",

    Blazer: "BLAZER",
    SafariSuit: "SAFARI SUIT",

    Shirt: "SHIRT",
    Trouser: "TROUSER",
    WaistCoat: "WAIST COAT",
    JawaharJacket: "JAWAHAR JACKET",
    KurtaPyjama: "KURTA PYJAMA",
    IndoWestern: "INDO WESTERN",
    JODHPURI: "JODHPURI",
    CowlKurta: "COWL KURTA",
    Tuxedoe: "TUXEDOE",
    //    case BlazerSports = "BLAZER SPORTS"
    //    case BlazerSafari = "BLAZER SAFARI"

    Kurta: "KURTA",
    Pyjama: "PYJAMA",
    StitchService: "STITCH SERVICE",
    Fabric: "FABRIC",
    None: 'None',
    Accessories: 'ACCESSORIES',
    NeckWear: 'NECKWEAR',
    Denim: 'DENIM',
    GolfTrouser: 'GOLF TROUSER',
    Jacket: 'JACKET',
    BomberJacket: 'BOMBER JACKET',
    FieldJacket: 'FIELD JACKET',
    SportJacket: 'SPORT JACKET',
    OverCoat: 'OVER COAT',
    WaistCoat: 'WAIST COAT',
    Polo: 'POLO',
    Pant: 'PANT',
    Bag: 'BAG',
    Shoes: 'SHOES',


    // Senator : "SENATOR",
    // CasualShirts : "CASUAL SHIRTS",
    // CasualPants : "CASUAL PANTS",
    // DanshikiWear : "DANSHIKI WEAR",
    // AgbadaSet : "AGBADA SET",
    // Shorts : "SHORTS",
    // NativeCap : "NATIVE CAP"







    //    Stich Service (instead of MTO)
    //    Jawahar Jacket (instead of JAWAHAR JACKET)
    //    JODHPURI (instead of Bandhgala)
    //    Blazer (single)
    //    Safari Suit

}


export const Stage_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? ''} onChange={onChangeFunc}>
            <option>{Stage.None}</option>
            <option>{Stage.Requested}</option>
            <option>{Stage.SentToFactory}</option>
            <option>{Stage.SentToVendor}</option>
            <option>{Stage.FactoryRecieved}</option>
            <option>{Stage.FactoryComplete}</option>
            <option>{Stage.FactorySent}</option>
            <option>{Stage.VendorRecieved}</option>
            <option>{Stage.VendorComplete}</option>
            <option>{Stage.VendorSent}</option>
            <option>{Stage.RecievedFromFactory}</option>
            <option>{Stage.RecievedFromVendor}</option>
            <option>{Stage.Tried}</option>
            <option>{Stage.Finished}</option>
            <option>{Stage.Delivered}</option>
        </select>
    )
}


export const Status_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? ''} onChange={onChangeFunc}>
            <option>{Status.None}</option>
            <option>{Status.Pending}</option>
            <option>{Status.Delivered}</option>
        </select>
    )
}



export const StyleSelectionProductType_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select has-background-grey-dark has-text-white ' + classNames} value={value ?? ''} onChange={onChangeFunc}>
            <option>{"All"}</option>
            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}

        </select>
    )
}


export const ProductType_options_NonFormik = (name, classNames, onChangeFunc, value, showNone) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? ''} onChange={onChangeFunc}>

            { showNone ? <option>{ProductType.None}</option> : null }

            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}


        </select>
    )
}




export const Profession_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? ''} onChange={onChangeFunc}>
            <option>{Profession.None}</option>
            <option>{Profession.Salaried}</option>
            <option>{Profession.Professional}</option>
            <option>{Profession.Businessman}</option>
            <option>{Profession.Politician}</option>
            <option>{Profession.Bureaucrat}</option>
        </select>
    )
}

export const SpendRange_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? 'None'} onChange={onChangeFunc}>
            <option>{SpendRange.None}</option>
            <option>{SpendRange.Regular}</option>
            <option>{SpendRange.Silver}</option>
            <option>{SpendRange.Gold}</option>
            <option>{SpendRange.Platinum}</option>
            <option>{SpendRange.Ultra}</option>
        </select>
    )
}

export const FromStoreSection_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? 'None'} onChange={onChangeFunc}>
            <option>{FromStoreSection.None}</option>
            <option>{FromStoreSection.Ethnic}</option>
            <option>{FromStoreSection.Western}</option>
            <option>{FromStoreSection.Stitch}</option>
            <option>{FromStoreSection.Stock}</option>
        </select>
    )
}


export const Purpose_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? ''} onChange={onChangeFunc}>
            <option>{Purpose.None}</option>
            <option>{Purpose.Create}</option>
            <option>{Purpose.Finishing}</option>
            <option>{Purpose.DryClean}</option>
        </select>
    )
}


export const SaleType_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? 'None'} onChange={onChangeFunc}>
            <option value={null}>{SaleType.None}</option>
            <option>{SaleType.Regular}</option>
            <option>{SaleType.Discount}</option>
            <option>{SaleType.Complemantory}</option>
        </select>
    )
}


export const ClientCategory_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? 'None'} onChange={onChangeFunc}>
            <option>{ClientCategory.None}</option>
            <option>{ClientCategory.AA}</option>
            <option>{ClientCategory.A}</option>
            <option>{ClientCategory.B}</option>
            <option>{ClientCategory.C}</option>
        </select>
    )
}


export const ClientAge_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? ''} onChange={onChangeFunc}>
            <option>{ClientAge.None}</option>
            <option>{ClientAge.to20}</option>
            <option>{ClientAge.to25}</option>
            <option>{ClientAge.to30}</option>
            <option>{ClientAge.to35}</option>
            <option>{ClientAge.to40}</option>
            <option>{ClientAge.to45}</option>
            <option>{ClientAge.to50}</option>
            <option>{ClientAge.to55}</option>
            <option>{ClientAge.to60}</option>
            <option>{ClientAge.above60}</option>
        </select>
    )
}


export const CustomMadePatternProduct_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select' + classNames} value={value ?? ''} onChange={onChangeFunc}>

            <option>{ProductType.None}</option>

            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}

            {/* <option>{ProductType.None}</option>
            <option>{ProductType.Tuxedoe}</option>
            <option>{ProductType.Suit2piece}</option>
            <option>{ProductType.Suit3piece}</option>
            <option>{ProductType.Coat}</option>
            <option>{ProductType.Shirt}</option>
            <option>{ProductType.Trouser}</option>
            <option>{ProductType.Blazer}</option>
            <option>{ProductType.WaistCoat}</option>

            <option>{ProductType.Sherwani}</option>
            <option>{ProductType.IndoWestern}</option>
            <option>{ProductType.SafariSuit}</option>
            <option>{ProductType.KurtaPyjama}</option>
            <option>{ProductType.Kurta}</option>
            <option>{ProductType.Pyjama}</option>
            <option>{ProductType.CowlKurta}</option>

            <option>{ProductType.StitchService}</option>
            <option>{ProductType.Fabric}</option>

            <option>{ProductType.Accessories}</option>
            <option>{ProductType.NeckWear}</option>
            <option>{ProductType.Denim}</option>
            <option>{ProductType.GolfTrouser}</option>
            <option>{ProductType.Jacket}</option>
            <option>{ProductType.BomberJacket}</option>
            <option>{ProductType.FieldJacket}</option>
            <option>{ProductType.SportJacket}</option>
            <option>{ProductType.OverCoat}</option>
            <option>{ProductType.WaistCoat}</option>
            <option>{ProductType.Polo}</option>
            <option>{ProductType.Bag}</option>
            <option>{ProductType.Shoes}</option> */}
        </select>
    )
}





export const ProductType_options = (name, classNames, onChangeFunc) => {
    return (
        <Field as='select' name={name} className={'select ' + classNames} onChange={onChangeFunc}>

            {/* <option>{ProductType.None}</option> */}

            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}
            {/* 
            <option>{ProductType.None}</option>
            <option>{ProductType.Tuxedoe}</option>
            <option>{ProductType.Suit2piece}</option>
            <option>{ProductType.Suit3piece}</option>
            <option>{ProductType.Coat}</option>
            <option>{ProductType.Shirt}</option>
            <option>{ProductType.Trouser}</option>
            <option>{ProductType.Blazer}</option>
            <option>{ProductType.WaistCoat}</option>

            <option>{ProductType.Sherwani}</option>
            <option>{ProductType.IndoWestern}</option>
            <option>{ProductType.SafariSuit}</option>
            <option>{ProductType.KurtaPyjama}</option>
            <option>{ProductType.Kurta}</option>
            <option>{ProductType.Pyjama}</option>
            <option>{ProductType.CowlKurta}</option>

            <option>{ProductType.StitchService}</option>
            <option>{ProductType.Fabric}</option>

            <option>{ProductType.Accessories}</option>
            <option>{ProductType.NeckWear}</option>
            <option>{ProductType.Denim}</option>
            <option>{ProductType.GolfTrouser}</option>
            <option>{ProductType.Jacket}</option>
            <option>{ProductType.BomberJacket}</option>
            <option>{ProductType.FieldJacket}</option>
            <option>{ProductType.SportJacket}</option>
            <option>{ProductType.OverCoat}</option>
            <option>{ProductType.WaistCoat}</option>
            <option>{ProductType.Polo}</option>
            <option>{ProductType.Bag}</option>
            <option>{ProductType.Shoes}</option> */}
        </Field>
    )
}


export const productType_allValues = () => {
    var products = []
    // <option>{ProductType.None}</option>
    products.push(ProductType.Tuxedoe)
    products.push(ProductType.Suit2piece)
    products.push(ProductType.Suit3piece)
    products.push(ProductType.Coat)
    products.push(ProductType.Shirt)
    products.push(ProductType.Trouser)
    products.push(ProductType.Blazer)
    products.push(ProductType.WaistCoat)
    products.push(ProductType.Sherwani)
    products.push(ProductType.IndoWestern)
    products.push(ProductType.SafariSuit)
    products.push(ProductType.KurtaPyjama)
    products.push(ProductType.Kurta)
    products.push(ProductType.Pyjama)
    products.push(ProductType.CowlKurta)
    products.push(ProductType.StitchService)
    products.push(ProductType.Fabric)
    products.push(ProductType.Accessories)
    products.push(ProductType.NeckWear)
    products.push(ProductType.Denim)
    products.push(ProductType.GolfTrouser)
    products.push(ProductType.Jacket)
    products.push(ProductType.BomberJacket)
    products.push(ProductType.FieldJacket)
    products.push(ProductType.SportJacket)
    products.push(ProductType.OverCoat)
    products.push(ProductType.Polo)
    products.push(ProductType.Pant)
    products.push(ProductType.Bag)
    products.push(ProductType.Shoes)

    // products.push(ProductType.Senator)
    // products.push(ProductType.CasualShirts)
    // products.push(ProductType.CasualPants)
    // products.push(ProductType.DanshikiWear)
    // products.push(ProductType.AgbadaSet)
    // products.push(ProductType.Shorts)
    // products.push(ProductType.NativeCap)


    products.sort(function (a, b) {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
    })

    return products
}




export const productType_isGroup = (type) => {
    switch (type) {
        case ProductType.Suit2piece: case ProductType.Suit3piece: case ProductType.KurtaPyjama: case ProductType.Sherwani: case ProductType.IndoWestern: case ProductType.Tuxedoe: case ProductType.SafariSuit: return true
        default: return false
    }
}

export const productType_groupProducts = (type) => {
    switch (type) {
        case ProductType.Sherwani: return [ProductType.Sherwani, ProductType.Kurta, ProductType.Pyjama]
        case ProductType.Suit2piece: return [ProductType.Coat, ProductType.Trouser]
        case ProductType.Suit3piece: return [ProductType.Coat, ProductType.Trouser, ProductType.WaistCoat]
        case ProductType.KurtaPyjama: return [ProductType.Kurta, ProductType.Pyjama]
        case ProductType.IndoWestern: return [ProductType.IndoWestern, ProductType.Trouser]
        case ProductType.Tuxedoe: return [ProductType.Tuxedoe, ProductType.Trouser]
        case ProductType.SafariSuit: return [ProductType.SafariSuit, ProductType.Trouser]
        default: return []
    }
}



// export const productType_readerValue = (type) => {
//     switch (type) {
//         case ProductType.Sherwani: return "SHERWANI"
//         case ProductType.Suit2piece: return "SUIT 2 PIECE"
//         case ProductType.Suit3piece: return "SUIT 3 PIECE"
//         case ProductType.Coat: return "COAT"
//         case ProductType.Blazer: return "BLAZER"
//         case ProductType.SafariSuit: return "SAFARI SUIT"
//         case ProductType.Shirt: return "SHIRT"
//         case ProductType.Trouser: return "TROUSER"
//         case ProductType.WaistCoat: return "WAIST COAT"
//         case ProductType.JawaharJacket: return "JAWAHAR JACKET"
//         case ProductType.KurtaPyjama: return "KURTA PYJAMA"
//         case ProductType.IndoWestern: return "INDO WESTERN"
//         case ProductType.JODHPURI: return "JODHPURI"
//         case ProductType.CowlKurta: return "COWL KURTA"
//         case ProductType.Tuxedoe: return "Tuxedoe"
//         //        case .BlazerSports  : return  "BLAZER SPORTS"
//         //        case .BlazerSafari  : return  "BLAZER SAFARI"
//         case ProductType.Kurta: return "KURTA"
//         case ProductType.Pyjama: return "PYJAMA"
//         case ProductType.StitchService: return "STITCH SERVICE"
//         case ProductType.Fabric: return "FABRIC"
//         default: break
//     }
// }

// export const defaultOffering = () => {
//     return ProductOffering.init(productType: self, unavailableAtLocIds: [])
// }


// export const func allDefaultOfferings = () => {
//     var prodOfferings = [ProductOffering]()
//     prodOfferings.append(self.Sherwani.defaultOffering())
//     prodOfferings.append(self.Suit2piece.defaultOffering())
//     prodOfferings.append(self.Suit3piece.defaultOffering())
//     prodOfferings.append(self.Coat.defaultOffering())
//     prodOfferings.append(self.Blazer.defaultOffering())
//     prodOfferings.append(self.SafariSuit.defaultOffering())
//     prodOfferings.append(self.Shirt.defaultOffering())
//     prodOfferings.append(self.Trouser.defaultOffering())
//     prodOfferings.append(self.WaistCoat.defaultOffering())
//     prodOfferings.append(self.JawaharJacket.defaultOffering())
//     prodOfferings.append(self.KurtaPyjama.defaultOffering())
//     prodOfferings.append(self.IndoWestern.defaultOffering())
//     prodOfferings.append(self.JODHPURI.defaultOffering())
//     prodOfferings.append(self.CowlKurta.defaultOffering())
//     prodOfferings.append(self.Tuxedoe.defaultOffering())
//     //            prodOfferings.append(self.BlazerSports.defaultOffering())
//     //            prodOfferings.append(self.BlazerSafari.defaultOffering())
//     prodOfferings.append(self.Kurta.defaultOffering())
//     prodOfferings.append(self.Pyjama.defaultOffering())
//     prodOfferings.append(self.StitchService.defaultOffering())
//     prodOfferings.append(self.Fabric.defaultOffering())
//     return prodOfferings
// }



// export const productType_image = (type) => {
//     switch (type) {
//         case ProductType.Sherwani: return imageNamed("aa-pi-Sherwani");
//         case ProductType.Suit2piece: return imageNamed("aa-pi-Suit2Piece");
//         case ProductType.Suit3piece: return imageNamed("aa-pi-Suit3Piece");
//         case ProductType.Coat: return imageNamed("aa-pi-Coat");
//         case ProductType.Blazer: return imageNamed("aa-pi-Blazer");
//         case ProductType.SafariSuit: return imageNamed("aa-pi-SafariSuit");
//         case ProductType.Shirt: return imageNamed("aa-pi-Shirt");
//         case ProductType.Trouser: return imageNamed("aa-pi-Trouser");
//         case ProductType.WaistCoat: return imageNamed("aa-pi-WaistCoat");
//         case ProductType.JawaharJacket: return imageNamed("aa-pi-JawaharJacket");
//         case ProductType.KurtaPyjama: return imageNamed("aa-pi-KurtaPyjama");
//         case ProductType.IndoWestern: return imageNamed("aa-pi-IndoWestern");
//         case ProductType.CowlKurta: return imageNamed("aa-pi-CowlKurta");
//         case ProductType.JODHPURI: return imageNamed("aa-pi-JODHPURI");
//         case ProductType.Tuxedoe: return imageNamed("aa-pi-Tuxedos");
//         //            case .BlazerSports : return imageNamed("aa-pi-BlazerSports");
//         //            case .BlazerSafari : return imageNamed("aa-pi-BlazerSafari");
//         case ProductType.Kurta: return imageNamed("aa-pi-Kurta");
//         case ProductType.Pyjama: return imageNamed("aa-pi-Pyjama");
//         case ProductType.StitchService: return imageNamed("aa-pi-MTO");
//         case ProductType.Fabric: return imageNamed("aa-pi-Stock");

//         //                case .Sherwani : return imageNamed("SHERWANI");
//         //    //            case .Suit2piece : return imageNamed("SUIT2PIECE");
//         //    //            case .Suit3piece : return imageNamed("SUIT3PIECE");
//         //                case .Coat : return imageNamed("COAT");
//         //                case .Shirt : return imageNamed("SHIRT");
//         //    //            case .Trouser : return imageNamed("TROUSER");
//         //                case .WaistCoat : return imageNamed("WAISTCOAT");
//         //    //            case .JawaharJacket : return imageNamed("JawaharJacket");
//         //    //            case .KurtaPyjama : return imageNamed("KURTAPYJAMA");
//         //    //            case .IndoWestern : return imageNamed("INDOWESTERN");
//         //    //            case .JODHPURI : return imageNamed("JODHPURI");
//         //    //            case .Tuxedoe : return imageNamed("Tuxedoe");
//         //    //            case .BlazerSports : return imageNamed("BLAZERSPORTS");
//         //    //            case .BlazerSafari : return imageNamed("BLAZERSAFARI");

//         //            default : return null
//         default: break
//     }
// }


// export const productType_allStyleNos = (type) => {
//     let emptyArr = []
//     switch (type) {
//         case ProductType.Sherwani: return addIntToString("SH-", 310, 999, 1)
//         case ProductType.Suit2piece: return addIntToString("S-", 1, 999, 1)
//         case ProductType.Suit3piece: return addIntToString("S-", 1, 999, 1)
//         case ProductType.Coat: return addIntToString("S-", 1, 200, 1)
//         case ProductType.Blazer: return addIntToString("BL-", 1, 200)
//         case ProductType.SafariSuit: return addIntToString("SS-", 1, 200, 1)
//         case ProductType.Shirt: return addIntToString("SH-", 101, 1999, 1)
//         case ProductType.Trouser: return addIntToString("P-", 1, 99, 1)
//         case ProductType.WaistCoat: return addIntToString("SL-", 1, 999, 1)
//         case ProductType.JawaharJacket: return addIntToString("JJ-", 1, 200, 1)
//         case ProductType.KurtaPyjama: return addIntToString("KP-", 1, 999, 1)
//         case ProductType.IndoWestern: return addIntToString("INDO-", 1001, 1999, 1)  // selfmade
//         case ProductType.JODHPURI: return addIntToString("BG-", 1, 200, 1)
//         case ProductType.CowlKurta: return addIntToString("Cowl-", 101, 9901, 100)
//         case ProductType.Tuxedoe: return addIntToString("TX-", 1, 200, 1)
//         //        case .BlazerSports  : return  addIntToString( "BL-",  1,  200, 1)
//         //        case .BlazerSafari  : return  addIntToString( "BL-",  1,  200, 1)
//         case ProductType.Kurta: return addIntToString("K-", 1, 200, 1)
//         case ProductType.Pyjama: return emptyArr
//         case ProductType.StitchService: return emptyArr
//         case ProductType.Fabric: return emptyArr
//         default: break
//     }
// }

// export const ProductType_StyleOptions = (product, name, classNames) => {
//     console.log('NNPRODUCT = ' + product)
//     let vals = productType_allStyleNos(product)
//     console.log('NNVALS = ' + vals)

//     return (
//         <select name={name} className={classNames}>
//             {vals.map((stl) => {
//                 return <option> {stl} </option>
//             })}
//         </select>
//     )
// }






export const Posture = {
    StanceNormal: "Stance Normal",
    StanceForwardLeaning: "Stance Forward Leaning",
    StanceErect: "Stance Erect",

    ShoulderFrontNormal: "Shoulder Front Normal",
    ShoulderFrontSteep: "Shoulder Front Steep",
    ShoulderFrontFlat: "Shoulder Front Flat",

    ShoulderBackSloping: "Shoulder Back Sloping",
    ShoulderBackRegular: "Shoulder Back Regular",
    ShoulderBackHigh: "Shoulder Back High",

    ChestThin: "Chest Thin",
    ChestFit: "Chest Fit",
    ChestNormal: "Chest Normal",
    ChestMuscular: "Chest Muscular",
    ChestLarge: "Chest Large",

    StomachThin: "Stomach Thin",
    StomachNormal: "Stomach Normal",
    StomachMedium: "Stomach Medium",
    StomachLarge: "Stomach Large",

    SeatThin: "Seat Thin",
    SeatNormal: "Seat Normal",
    SeatCurved: "Seat Curved",
    SeatLarge: "Seat Large",

    blank: ""

}


export const imageUrlForPostures = (posture) => {

    let baseUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/karanmoin/postures/"
    let imageName = ""
    
    switch (posture) {
       case Posture.StanceNormal: imageName = "km_StanceNormal.png" ; break;
       case Posture.StanceForwardLeaning: imageName = "km_StanceForwardLeaning.png";break;
       case Posture.StanceErect: imageName = "km_StanceErect.png";break;

       case Posture.ShoulderFrontNormal: imageName = "km_ShoulderFrontNormal.png";break;
       case Posture.ShoulderFrontSteep: imageName = "km_ShoulderFrontSteep.png";break;
       case Posture.ShoulderFrontFlat: imageName = "km_ShoulderFrontFlat.png";break;
    
       case Posture.ShoulderBackSloping: imageName = "km_ShoulderBackSloping.png";break;
       case Posture.ShoulderBackRegular: imageName = "km_ShoulderBackRegular.png";break;
       case Posture.ShoulderBackHigh: imageName = "km_ShoulderBackHigh.png";break;
    
       case Posture.ChestThin: imageName = "km_ChestThin.png";break;
       case Posture.ChestFit: imageName = "km_ChestFit.png";break;
       case Posture.ChestNormal: imageName = "km_ChestNormal.png";break;
       case Posture.ChestMuscular: imageName = "km_ChestMuscular.png";break;
       case Posture.ChestLarge: imageName = "km_ChestLarge.png";break;
    
       case Posture.StomachThin: imageName = "km_StomachThin.png";break;
       case Posture.StomachNormal: imageName = "km_StomachNormal.png";break;
       case Posture.StomachMedium: imageName = "km_StomachMedium.png";break;
       case Posture.StomachLarge: imageName = "km_StomachLarge.png";break;
    
       case Posture.SeatThin: imageName = "km_SeatThin.png";break;
       case Posture.SeatNormal: imageName = "km_SeatNormal.png";break;
       case Posture.SeatCurved: imageName = "km_SeatCurved.png";break;
       case Posture.SeatLarge: imageName = "km_SeatLarge.png";break;
       default : break;
    }

    if (imageName === "" || posture === null){
        return imageName
    }

    return baseUrl + imageName ;
}



export const posture_typeName = (pos) => {
    switch (pos) {
        case Posture.StanceNormal: return "Stance"
        case Posture.StanceForwardLeaning: return "Stance"
        case Posture.StanceErect: return "Stance"
        case Posture.ShoulderFrontNormal: return "Shoulder Front"
        case Posture.ShoulderFrontSteep: return "Shoulder Front"
        case Posture.ShoulderFrontFlat: return "Shoulder Front"
        case Posture.ShoulderBackSloping: return "Shoulder Back"
        case Posture.ShoulderBackRegular: return "Shoulder Back"
        case Posture.ShoulderBackHigh: return "Shoulder Back"
        case Posture.ChestThin: return "Chest"
        case Posture.ChestFit: return "Chest"
        case Posture.ChestNormal: return "Chest"
        case Posture.ChestMuscular: return "Chest"
        case Posture.ChestLarge: return "Chest"
        case Posture.StomachThin: return "Stomach"
        case Posture.StomachNormal: return "Stomach"
        case Posture.StomachMedium: return "Stomach"
        case Posture.StomachLarge: return "Stomach"
        case Posture.SeatThin: return "Seat"
        case Posture.SeatNormal: return "Seat"
        case Posture.SeatCurved: return "Seat"
        case Posture.SeatLarge: return "Seat"
        case Posture.blank: return ""
        default: break
    }
}

export const posture_allValues = (pos) => {
    var vals = []
    vals.push(Posture.StanceNormal)
    vals.push(Posture.StanceForwardLeaning)
    vals.push(Posture.StanceErect)
    vals.push(Posture.ShoulderFrontNormal)
    vals.push(Posture.ShoulderFrontSteep)
    vals.push(Posture.ShoulderFrontFlat)
    vals.push(Posture.ShoulderBackSloping)
    vals.push(Posture.ShoulderBackRegular)
    vals.push(Posture.ShoulderBackHigh)
    vals.push(Posture.ChestThin)
    vals.push(Posture.ChestFit)
    vals.push(Posture.ChestNormal)
    vals.push(Posture.ChestMuscular)
    vals.push(Posture.ChestLarge)
    vals.push(Posture.StomachThin)
    vals.push(Posture.StomachNormal)
    vals.push(Posture.StomachMedium)
    vals.push(Posture.StomachLarge)
    vals.push(Posture.SeatThin)
    vals.push(Posture.SeatNormal)
    vals.push(Posture.SeatCurved)
    vals.push(Posture.SeatLarge)
    return vals
}



export const posture_readerValue = (pos) => {
    switch (pos) {
        case ProductType.StanceNormal: return "Stance Normal"
        case ProductType.StanceForwardLeaning: return "Stance Forward Leaning"
        case ProductType.StanceErect: return "Stance Erect"
        case ProductType.ShoulderFrontNormal: return "Shoulder Front Normal"
        case ProductType.ShoulderFrontSteep: return "Shoulder Front Steep"
        case ProductType.ShoulderFrontFlat: return "Shoulder Front Flat"
        case ProductType.ShoulderBackSloping: return "Shoulder Back Sloping"
        case ProductType.ShoulderBackRegular: return "Shoulder Back Regular"
        case ProductType.ShoulderBackHigh: return "Shoulder Back High"
        case ProductType.ChestThin: return "Chest Thin"
        case ProductType.ChestFit: return "Chest Fit"
        case ProductType.ChestNormal: return "Chest Normal"
        case ProductType.ChestMuscular: return "Chest Muscular"
        case ProductType.ChestLarge: return "Chest Large"
        case ProductType.StomachThin: return "Stomach Thin"
        case ProductType.StomachNormal: return "Stomach Normal"
        case ProductType.StomachMedium: return "Stomach Medium"
        case ProductType.StomachLarge: return "Stomach Large"
        case ProductType.SeatThin: return "Seat Thin"
        case ProductType.SeatNormal: return "Seat Normal"
        case ProductType.SeatCurved: return "Seat Curved"
        case ProductType.SeatLarge: return "Seat Large"
        case ProductType.blank: return ""
        default: break
    }
}

export const posture_readerValueInternal = (pos) => {
    switch (pos) {
        case ProductType.StanceNormal: return "Normal"
        case ProductType.StanceForwardLeaning: return "Forward Leaning"
        case ProductType.StanceErect: return "Erect"
        case ProductType.ShoulderFrontNormal: return "Normal"
        case ProductType.ShoulderFrontSteep: return "Steep"
        case ProductType.ShoulderFrontFlat: return "Flat"
        case ProductType.ShoulderBackSloping: return "Sloping"
        case ProductType.ShoulderBackRegular: return "Regular"
        case ProductType.ShoulderBackHigh: return "High"
        case ProductType.ChestThin: return "Thin"
        case ProductType.ChestFit: return "Fit"
        case ProductType.ChestNormal: return "Normal"
        case ProductType.ChestMuscular: return "Muscular"
        case ProductType.ChestLarge: return "Large"
        case ProductType.StomachThin: return "Thin"
        case ProductType.StomachNormal: return "Normal"
        case ProductType.StomachMedium: return "Medium"
        case ProductType.StomachLarge: return "Large"
        case ProductType.SeatThin: return "Thin"
        case ProductType.SeatNormal: return "Normal"
        case ProductType.SeatCurved: return "Curved"
        case ProductType.SeatLarge: return "Large"
        case ProductType.blank: return ""
        default: break
    }
}


export const posture_imageName = (pos) => {
    switch (pos) {
        case ProductType.StanceNormal: return "km_StanceNormal"
        case ProductType.StanceForwardLeaning: return "km_StanceForwardLeaning"
        case ProductType.StanceErect: return "km_StanceErect"
        case ProductType.ShoulderFrontNormal: return "km_ShoulderFrontNormal"
        case ProductType.ShoulderFrontSteep: return "km_ShoulderFrontSteep"
        case ProductType.ShoulderFrontFlat: return "km_ShoulderFrontFlat"
        case ProductType.ShoulderBackSloping: return "km_ShoulderBackSloping"
        case ProductType.ShoulderBackRegular: return "km_ShoulderBackRegular"
        case ProductType.ShoulderBackHigh: return "km_ShoulderBackHigh"
        case ProductType.ChestThin: return "km_ChestThin"
        case ProductType.ChestFit: return "km_ChestFit"
        case ProductType.ChestNormal: return "km_ChestNormal"
        case ProductType.ChestMuscular: return "km_ChestMuscular"
        case ProductType.ChestLarge: return "km_ChestLarge"
        case ProductType.StomachThin: return "km_StomachThin"
        case ProductType.StomachNormal: return "km_StomachNormal"
        case ProductType.StomachMedium: return "km_StomachMedium"
        case ProductType.StomachLarge: return "km_StomachLarge"
        case ProductType.SeatThin: return "km_SeatThin"
        case ProductType.SeatNormal: return "km_SeatNormal"
        case ProductType.SeatCurved: return "km_SeatCurved"
        case ProductType.SeatLarge: return "km_SeatLarge"
        case ProductType.blank: return ""
        default: break
    }
}

export const posture_toImage = (pos) => {
    // if let image = UIImage.init(named: self.imageName()){
    //     return image
    // }
    // return null
}

export const posture_toImageWithReaderValue = () => {
    // if let image = UIImage.init(named: self.imageName()){
    //     let imageWithCaption = textToImage(drawText: self.readerValue(), inImage: image)
    //     return imageWithCaption
    // }
    // return null
}








// export const CustomMadePatternProduct_options = (name, classNames) => {
//     return (
//         <select name={name} className={classNames}>
//             <option>{CustomMadePatternProduct.Sherwani}         </option>
//             <option>{CustomMadePatternProduct.Suit2piece}</option>
//             <option>{CustomMadePatternProduct.Suit3piece}</option>
//             <option>{CustomMadePatternProduct.Coat}</option>
//             <option>{CustomMadePatternProduct.Shirt}</option>
//             <option>{CustomMadePatternProduct.Trouser}</option>
//             <option>{CustomMadePatternProduct.WaistCoat}</option>
//             <option>{CustomMadePatternProduct.JawaharJacket}</option>
//             <option>{CustomMadePatternProduct.KurtaPyjama}</option>
//             <option>{CustomMadePatternProduct.IndoWestern}</option>
//             <option>{CustomMadePatternProduct.JODHPURI}</option>
//             <option>{CustomMadePatternProduct.Tuxedoe}</option>
//             <option>{CustomMadePatternProduct.BlazerSports}</option>
//             <option>{CustomMadePatternProduct.BlazerSafari}</option>
//         </select>
//     )
// }


// export const CustomMadePatternProduct_allValues = (pat) => {
//     var products = []
//     products.push(CustomMadePatternProduct.Sherwani)
//     products.push(CustomMadePatternProduct.Suit2piece)
//     products.push(CustomMadePatternProduct.Suit3piece)
//     products.push(CustomMadePatternProduct.Coat)
//     products.push(CustomMadePatternProduct.Shirt)
//     products.push(CustomMadePatternProduct.Trouser)
//     products.push(CustomMadePatternProduct.WaistCoat)
//     products.push(CustomMadePatternProduct.JawaharJacket)
//     products.push(CustomMadePatternProduct.KurtaPyjama)
//     products.push(CustomMadePatternProduct.IndoWestern)
//     products.push(CustomMadePatternProduct.JODHPURI)
//     products.push(CustomMadePatternProduct.Tuxedoe)
//     products.push(CustomMadePatternProduct.BlazerSports)
//     products.push(CustomMadePatternProduct.BlazerSafari)
//     return products
// }


// export const CustomMadePatternProduct_readerValue = (pat) => {
//     switch (pat) {
//         case CustomMadePatternProduct.Sherwani: return "SHERWANI"
//         case CustomMadePatternProduct.Suit2piece: return "SUIT 2 PIECE"
//         case CustomMadePatternProduct.Suit3piece: return "SUIT 3 PIECE"
//         case CustomMadePatternProduct.Coat: return "COAT"
//         case CustomMadePatternProduct.Shirt: return "SHIRT"
//         case CustomMadePatternProduct.Trouser: return "TROUSER"
//         case CustomMadePatternProduct.WaistCoat: return "WAIST COAT"
//         case CustomMadePatternProduct.JawaharJacket: return "JAWAHAR JACKET"
//         case CustomMadePatternProduct.KurtaPyjama: return "KURTA PYJAMA"
//         case CustomMadePatternProduct.IndoWestern: return "INDO WESTERN"
//         case CustomMadePatternProduct.JODHPURI: return "JODHPURI"
//         case CustomMadePatternProduct.Tuxedoe: return "Tuxedoe"
//         case CustomMadePatternProduct.BlazerSports: return "BLAZER SPORTS"
//         case CustomMadePatternProduct.BlazerSafari: return "BLAZER SAFARI"
//         default: break
//     }
// }



//Age Group: 0-20, 20-25, (so on with 5 yrs gap, till 60 Years)

export const ClientAge = {
    None: "None",
    to20: "0 to 20",
    to25: "20 to 25",
    to30: "25 to 30",
    to35: "30 to 35",
    to40: "35 to 40",
    to45: "40 to 45",
    to50: "45 to 50",
    to55: "50 to 55",
    to60: "55 to 60",
    above60: "Above 60"
}

export const ClientAge_options = (name, classNames) => {
    return (
        <Field as='select' name={name} className={'select ' + classNames}>
            <option>{ClientAge.None}</option>
            <option>{ClientAge.to20}</option>
            <option>{ClientAge.to25}</option>
            <option>{ClientAge.to30}</option>
            <option>{ClientAge.to35}</option>
            <option>{ClientAge.to40}</option>
            <option>{ClientAge.to45}</option>
            <option>{ClientAge.to50}</option>
            <option>{ClientAge.to55}</option>
            <option>{ClientAge.to60}</option>
            <option>{ClientAge.above60}</option>
        </Field>
    )
}

export const ClientAge_allValues = () => {
    var vals = []
    vals.push(ClientAge.None)
    vals.push(ClientAge.to20)
    vals.push(ClientAge.to25)
    vals.push(ClientAge.to30)
    vals.push(ClientAge.to35)
    vals.push(ClientAge.to40)
    vals.push(ClientAge.to45)
    vals.push(ClientAge.to50)
    vals.push(ClientAge.to55)
    vals.push(ClientAge.to60)
    vals.push(ClientAge.above60)
    return vals
}


export const ClientAge_readerValue = (ca) => {
    switch (ca) {
        case ClientAge.None: return "None"
        case ClientAge.to20: return "0 to 20"
        case ClientAge.to25: return "20 to 25"
        case ClientAge.to30: return "25 to 30"
        case ClientAge.to35: return "30 to 35"
        case ClientAge.to40: return "35 to 40"
        case ClientAge.to45: return "40 to 45"
        case ClientAge.to50: return "45 to 50"
        case ClientAge.to55: return "50 to 55"
        case ClientAge.to60: return "55 to 60"
        case ClientAge.above60: return "Above 60"
        default: break
    }
}




export const ClientCategory = {
    None: "None",
    AA: "AA",
    A: "A",
    B: "B",
    C: "C"
}

export const ClientCategory_options = (name, classNames) => {
    return (
        <Field as='select' name={name} className={'select ' + classNames}>
            <option>{ClientCategory.None}</option>
            <option>{ClientCategory.AA}</option>
            <option>{ClientCategory.A}</option>
            <option>{ClientCategory.B}</option>
            <option>{ClientCategory.C}</option>
        </Field>
    )
}

export const ClientCategory_allValues = () => {
    var vals = []
    vals.push(ClientCategory.None)
    vals.push(ClientCategory.AA)
    vals.push(ClientCategory.A)
    vals.push(ClientCategory.B)
    vals.push(ClientCategory.C)
    return vals
}



export const ClientCategory_readerValue = (cc) => {
    switch (checkoutPages) {
        case ClientCategory.None: return "None"
        case ClientCategory.AA: return "AA"
        case ClientCategory.A: return "A"
        case ClientCategory.B: return "B"
        case ClientCategory.C: return "C"
        default: break
    }
}


export const Profession = {
    None: "None",
    Salaried: "Salaried",
    Professional: "Professional",
    Businessman: "Businessman",
    Politician: "Politician",
    Bureaucrat: "Bureaucrat"
}

export const Profession_options = (name, classNames) => {
    return (
        <Field as='select' name={name} className={'select ' + classNames}>
            <option>{Profession.None}</option>
            <option>{Profession.Salaried}</option>
            <option>{Profession.Professional}</option>
            <option>{Profession.Businessman}</option>
            <option>{Profession.Politician}</option>
            <option>{Profession.Bureaucrat}</option>
        </Field>
    )
}

export const Profession_allValues = () => {
    var vals = []
    vals.push(Profession.None)
    vals.push(Profession.Salaried)
    vals.push(Profession.Professional)
    vals.push(Profession.Businessman)
    vals.push(Profession.Politician)
    vals.push(Profession.Bureaucrat)
    return vals
}



export const Profession_readerValue = (pf) => {
    switch (pf) {
        case Profession.None: return "None"
        case Profession.Salaried: return "Salaried"
        case Profession.Professional: return "Professional"
        case Profession.Businessman: return "Businessman"
        case Profession.Politician: return "Politician"
        case Profession.Bureaucrat: return "Bureaucrat"
        default: break
    }
}


// New Common SpendRanges


export const SpendRange = {
    None: "None",
    Regular: "Regular",
    Silver: "Silver",
    Gold: "Gold",
    Platinum: "Platinum",
    Ultra: "Ultra",
}



export const SpendRange_options = (name, classNames) => {
    return (
        <Field as='select' name={name} className={'select ' + classNames}>
            <option>{SpendRange.Regular}</option>
            <option>{SpendRange.Silver}</option>
            <option>{SpendRange.Gold}</option>
            <option>{SpendRange.Platinum}</option>
            <option>{SpendRange.Ultra}</option>
            <option>{SpendRange.None}</option>
        </Field>
    )
}

export const SpendRange_allValues = () => {
    var vals = []
    vals.push(SpendRange.Regular)
    vals.push(SpendRange.Silver)
    vals.push(SpendRange.Gold)
    vals.push(SpendRange.Platinum)
    vals.push(SpendRange.Ultra)
    vals.push(SpendRange.None)
    return vals
}



export const SpendRange_readerValue = (sr) => {
    switch (sr) {
        case SpendRange.Regular: return "Regular"
        case SpendRange.Silver: return "Silver"
        case SpendRange.Gold: return "Gold"
        case SpendRange.Platinum: return "Platinum"
        case SpendRange.Ultra: return "Ultra"
        case SpendRange.None: return "None"
        default: break
    }
}


export const FromStoreSection = {
    None: "None",
    Ethnic: "Ethnic Section",
    Western: "Western Section",
    Stitch: "Stitch Section",
    Stock: "Stock Section"
}


export const FromStoreSection_options = (name, classNames) => {
    return (
        <Field as='select' name={name} className={'select ' + classNames}>
            <option>{FromStoreSection.Ethnic}</option>
            <option>{FromStoreSection.Western}</option>
            <option>{FromStoreSection.Stitch}</option>
            <option>{FromStoreSection.Stock}</option>
            <option>{FromStoreSection.None}</option>
        </Field>
    )
}

export const FromStoreSection_allValues = () => {
    var vals = []
    vals.push(FromStoreSection.None)
    vals.push(FromStoreSection.Ethnic)
    vals.push(FromStoreSection.Western)
    vals.push(FromStoreSection.Stitch)
    vals.push(FromStoreSection.Stock)
    return vals
}


export const FromStoreSection_readerValue = (fss) => {
    switch (fss) {
        case FromStoreSection.None: return "None"
        case FromStoreSection.Ethnic: return "Ethnic Section"
        case FromStoreSection.Western: return "Western Section"
        case FromStoreSection.Stitch: return "Stitch Section"
        case FromStoreSection.Stock: return "Stock Section"
        default: break
    }
}



export const SaleType = {
    Regular: "Regular",
    Discount: "Discount",
    Complemantory: "Complemantory",
    None: "None"
}


export const SaleType_options = (name, classNames) => {
    return (
        <Field as='select' name={name} className={'select ' + classNames}>
            <option>{SaleType.Regular}</option>
            <option>{SaleType.Discount}</option>
            <option>{SaleType.Complemantory}</option>
            <option>{SaleType.None}</option>
        </Field>
    )
}

export const SaleType_allValues = () => {
    var vals = []
    vals.push(SaleType.Regular)
    vals.push(SaleType.Discount)
    vals.push(SaleType.Complemantory)
    vals.push(SaleType.None)
    return vals
}



export const SaleType_readerValue = (st) => {
    switch (st) {
        case SaleType.Regular: return "Regular"
        case SaleType.Discount: return "Discount"
        case SaleType.Complemantory: return "Complemantory"
        case SaleType.None: return "None"
        default: break
    }
}




export const HasRequestedQuantity = {
    None: "None",
    Yes: "Yes"
}

export const HasRequestedQuantity_allValues = () => {
    var vals = []
    vals.push(HasRequestedQuantity.None)
    vals.push(HasRequestedQuantity.Yes)
    return vals
}



export const HasRequestedQuantity_readerValue = (hrq) => {
    switch (hrq) {
        case HasRequestedQuantity.None: return "None"
        case HasRequestedQuantity.Yes: return "Yes"
        default: break
    }
}

export const HasRequestedQuantity_realValue = (hrq) => {
    switch (hrq) {
        case HasRequestedQuantity.None: return null
        case HasRequestedQuantity.Yes: return true
        default: return null
    }
}




export const Flag = {
    Y: "Y",
    R: "R",
    G: "G"
}

export const Flag_allValues = () => {
    var vals = []
    vals.push(Flag.Y)
    vals.push(Flag.R)
    vals.push(Flag.G)
    return vals
}

export const Flag_readerValue = (f) => {
    switch (f) {
        case Flag.Y: return "Y"
        case Flag.R: return "R"
        case Flag.G: return "G"
        default: break
    }
}

export const Flag_toString = (flagsArray) => {
    var str = ""
    for (let i = 0; i < flagsArray.length; i++) {
        const fl = flagsArray[i];
        str += fl.readerValue() + " "
    }

    return str
}

export const Flag_attributedStringFrom = (string) => {
    // let splitStr = string.split(separator: " ")
    // var flags = [Flag]()
    // for flStr in splitStr {
    //     if flStr == "Y" || flStr == "R" || flStr == "G" {
    //         let flag = Flag(rawValue: String(flStr))
    //         flags.append(flag!)
    //     }
    // }
    // print("ATTRIBUTED FLAGS = \(flags)")
    // return attributedString(flags: flags)
}

export const Flag_attributedString = (flagsArray) => {

    // let dotStr = "•"

    // let range = (dotStr as NSString).range(of: dotStr)
    // let redStrAttr = NSMutableAttributedString.init(string: dotStr)
    // redStrAttr.addAttribute(NSAttributedString.Key.foregroundColor, value: UIColor.red, range: range)
    // redStrAttr.addAttribute(NSAttributedString.Key.font, value: UIFont.systemFont(ofSize: 24.0, weight: .semibold), range: range)

    // let greenStrAttr = NSMutableAttributedString.init(string: dotStr)
    // greenStrAttr.addAttribute(NSAttributedString.Key.foregroundColor, value: UIColor.green, range: range)
    // greenStrAttr.addAttribute(NSAttributedString.Key.font, value: UIFont.systemFont(ofSize: 24.0, weight: .semibold), range: range)

    // let yellowStrAttr = NSMutableAttributedString.init(string: dotStr)
    // yellowStrAttr.addAttribute(NSAttributedString.Key.foregroundColor, value: UIColor.yellow, range: range)
    // yellowStrAttr.addAttribute(NSAttributedString.Key.font, value: UIFont.systemFont(ofSize: 24.0, weight: .semibold), range: range)

    // let blankStr = NSMutableAttributedString.init(string: "  ")
    // let fullStr = NSMutableAttributedString()


    // for fl in flags {
    //     switch fl {
    //         case .Y: fullStr.append(yellowStrAttr); break;
    //         case .R: fullStr.append(redStrAttr); break;
    //         case .G: fullStr.append(greenStrAttr); break;
    //     }
    //     fullStr.append(blankStr)
    // }


    // return fullStr
}




export const ChallanType = {
    Store: "Store Challan",
    Factory: "Factory Challan"
}

export const ChallanType_allValues = () => {
    var vals = []
    vals.push(ChallanType.Store)
    vals.push(ChallanType.Factory)
    return vals
}



export const ChallanType_readerValue = (ct) => {
    switch (ct) {
        case ChallanType.Store: return "Store Challan"
        case ChallanType.Factory: return "Factory Challan"
        default: break
    }
}






export const Stage = {  // For Products
    Requested: "Requested",

    SentToVendor: "Sent To Vendor",   // Vendor
    SentToFactory: "Sent to Factory",

    FactoryRecieved: "Factory Recieved",
    FactoryComplete: "Factory Complete",
    FactorySent: "Factory Sent",

    VendorRecieved: "Vendor Recieved",
    VendorComplete: "Vendor Complete",
    VendorSent: "Vendor Sent",

    RecievedFromFactory: "Recieved From Factory",
    RecievedFromVendor: "Recieved From Vendor",   // Vendor

    Tried: "Tried",
    Finished: "Finished",
    Delivered: "Delivered",
    None: ''
}




export const Stage_allStages = () => {
    let stages = [
        Stage.Requested,

        Stage.SentToFactory,
        Stage.SentToVendor,

        Stage.FactoryRecieved,
        Stage.FactoryComplete,
        Stage.FactorySent,

        Stage.VendorRecieved,
        Stage.VendorComplete,
        Stage.VendorSent,

        Stage.RecievedFromFactory,
        Stage.RecievedFromVendor,

        Stage.Tried,
        Stage.Finished,
        Stage.Delivered
    ]
    return stages
}


export const Stage_allValues = () => {
    var stages = []
    stages.push(Stage.Requested)

    stages.push(Stage.SentToFactory)
    stages.push(Stage.SentToVendor)

    stages.push(Stage.FactoryRecieved)
    stages.push(Stage.FactoryComplete)
    stages.push(Stage.FactorySent)

    stages.push(Stage.VendorRecieved)
    stages.push(Stage.VendorComplete)
    stages.push(Stage.VendorSent)


    stages.push(Stage.RecievedFromFactory)
    stages.push(Stage.RecievedFromVendor)

    stages.push(Stage.Tried)
    stages.push(Stage.Finished)
    stages.push(Stage.Delivered)
    return stages
}


export const Stage_readerValue = (st) => {
    switch (st) {
        case Stage.Requested: return "Requested"

        case Stage.SentToFactory: return "Sent to Factory"
        case Stage.SentToVendor: return "Sent To Vendor"

        case Stage.FactoryRecieved: return "Factory Recieved"
        case Stage.FactoryComplete: return "Factory Complete"
        case Stage.FactorySent: return "Factory Sent"

        case Stage.VendorRecieved: return "Vendor Recieved"
        case Stage.VendorComplete: return "Vendor Complete"
        case Stage.VendorSent: return "Vendor Sent"

        case Stage.RecievedFromFactory: return "Recieved From Factory"
        case Stage.RecievedFromVendor: return "Recieved From Vendor"

        case Stage.Tried: return "Tried"
        case Stage.Finished: return "Finished"
        case Stage.Delivered: return "Delivered"
        default: break
    }
}




export const Purpose = {
    Create: "To Create",
    Finishing: "For Alteration",
    DryClean: "Dry Clean",
    None: "None"
}

export const Purpose_allValues = () => {
    var purposes = []
    purposes.push(Purpose.Create)
    purposes.push(Purpose.Finishing)
    purposes.push(Purpose.DryClean)
    return purposes
}

export const Purpose_readerValue = (p) => {
    switch (p) {
        case Purpose.Create: return "To Create"
        case Purpose.Finishing: return "For Alteration"
        case Purpose.DryClean: return "Dry Clean"
        default: break
    }
}


export const Status = { // For Order
    Pending: "PENDING",
    Delivered: "DELIVERED",
    None: 'NONE'
}

export const Status_allValues = () => {
    var statuses = []
    statuses.push(Status.PENDING)
    statuses.push(Status.DELIVERED)
    return statuses
}

export const Status_readerValue = (st) => {
    switch (st) {
        case Status.Pending: return "PENDING"
        case Status.Delivered: return "DELIVERED"
        default: break
    }
}




export const End = {
    Store: "Store End",
    Factory: "Factory End"
}


export const End_readerValue = (end) => {
    switch (end) {
        case End.Store: return "Store End"
        case End.Factory: return "Factory End"
        default: break
    }
}



export const RemarkType = {
    product: "Product",
    stock: "Stock"
}

export const RemarkType_allValues = () => {
    var types = []
    types.push(RemarkType.product)
    types.push(RemarkType.stock)
    return types
}


export const RemarkType_readerValue = (rt) => {
    switch (rt) {
        case RemarkType.product: return "Product"
        case RemarkType.stock: return "Stock"
        default: break
    }
}



export const StockRemarkType = {
    used: "USED",
    sold: "SOLD",
    added: "ADDED",
    reduced: "REDUCED",
    audited: "AUDITED"
}

export const StockRemarkType_allValues = () => {
    var types = []
    types.push(StockRemarkType.used)
    types.push(StockRemarkType.sold)
    types.push(StockRemarkType.added)
    types.push(StockRemarkType.reduced)
    types.push(StockRemarkType.audited)
    return types
}



export const StockRemarkType_readerValue = (st) => {
    switch (st) {
        case StockRemarkType.used: return "USED"
        case StockRemarkType.sold: return "SOLD"
        case StockRemarkType.added: return "ADDED"
        case StockRemarkType.reduced: return "REDUCED"
        case StockRemarkType.audited: return "AUDITED"
        default: break
    }
}


export const Department = {
    CLIENTS: "Clients Department",
    ORDERS: "Orders Department",
    STOCK: "Stock Department",
    BILLING: "Billing Department",
    SHOWCASE: "Showcase Department",
    USERS: "Users Depertment",
    TRANSIT: "Transit Depertment",
    REPORTS: "Reports Depertment",
    SETTINGS: "Settings Depertment",
    NONE: ""

}



export const Department_readerValue = (dp) => {
    switch (dp) {
        case Department.CLIENTS: return "Clients Department"
        case Department.ORDERS: return "Orders Department"
        case Department.STOCK: return "Stock Department"
        case Department.BILLING: return "Billing Department"
        case Department.SHOWCASE: return "Showcase Department"
        case Department.USERS: return "Users Depertment"
        case Department.TRANSIT: return "Challans Depertment"
        case Department.REPORTS: return "Reports Depertment"
        case Department.SETTINGS: return "Settings Depertment"
        case Department.NONE: return ""
        default: break
    }
}

export const Department_title = (dp) => {
    switch (dp) {
        case Department.CLIENTS: return "CLIENTS"
        case Department.ORDERS: return "ORDERS"
        case Department.STOCK: return "STOCKS"
        case Department.BILLING: return "BILLING"
        case Department.SHOWCASE: return "SLIDES"
        case Department.USERS: return "USERS"
        case Department.TRANSIT: return "TRANSIT"
        case Department.REPORTS: return "REPORTS"
        case Department.SETTINGS: return "SETTINGS"
        case Department.NONE: return ""
        default: break
    }
}


// export const Screen = {
//     NewOrder = "New Order screen"
//     ProcessOrder = "Process Order screen "
//     PendingOrders_Store = "Pending Orders screen (Store)"
//     SendOrders_Store = "Send Orders screen (Store)"
//     RecieveOrders_Store = "Recieve Orders screen (Store)"
//     OrdersRecieved_Factory = "Confirm Orders screen (Factory)"
//     CompleteOrders_Factory = "Complete Orders screen (Factory)"
//     SendOrders_Factory = "Send Orders screen (Factory)"
//     CustomerDealing = "Client Window screen"
//     ApproveTrial = "Approve Trial screen"
//     ApproveFinishing = "Approve Alteration screen"
//     ApproveDelivery = "Approve Delivery screen"
//     ViewStatus = "View Status screen"
//     Search_Store = "Search screen (Store)"
//     Search_Factory = "Search screen (Factory)"
//     AllClients = "All Clients"
//     Search_Clients = "Client Search"
//     StockTypes = "All Stocks"
//     Search_Stocks = "Stock Search"
//     Positions = "Positions"
//     Users = "Users"
//     Craftsmen = "Craftsmen"
//     Locations = "Locations"
//     Challans = "Challans"
//     ScanSendToFactory = "Scan Send To Factory"
//     ScanRecieveFromFactory = "Scan Recieve From Factory"
//     ScanSendToStore = "Scan Send To Store"
//     ScanRecieveFromStore = "Scan Recieve From Store"
//     ScanInfo = "Scan Info"
//     ClientReports = "Client Reports"
//     OrderReports = "Order Reports"
//     ProductReports = "Product Reports"
//     StockReports = "Stock Reports"
//     ProductSettings = "ProductSettings"
//     TemplateSettings = "TemplateSettings"
//     LocationSettings = "LocationSettings"

// }


// export static const Screen_screenTitle = (screen: Screen) => {
//     switch screen {
//         case NewOrder: return "New Order"
//         case ProcessOrder: return "Process Order"
//         case PendingOrders_Store: return "Pending Orders"
//         case SendOrders_Store: return "Send Orders"
//         case RecieveOrders_Store: return "Recieve Orders"
//         case OrdersRecieved_Factory: return "Confirm Orders"
//         case CompleteOrders_Factory: return "Complete Orders"
//         case SendOrders_Factory: return "Send Orders"
//         case CustomerDealing: return "Client Window"
//         case ApproveTrial: return "Approve Trial"
//         case ApproveFinishing: return "Approve Alteration"
//         case ApproveDelivery: return "Deliver Products"
//         case ViewStatus: return "View Status"
//         case Search_Store: return "Store Search"  // Changed from Store Reports
//         case Search_Factory: return "Factory Search" // Changed form Factory Reports
//         case AllClients: return "All Clients"
//         case Search_Clients: return "Client Search"
//         case StockTypes: return "Stock Types"
//         case Search_Stocks: return "Stock Search"
//         case Positions: return "Positions"
//         case Users: return "Users"
//         case Craftsmen: return "Craftsmen"
//         case Locations: return "Locations"
//         case Challans: return "Challans"
//         case ScanSendToFactory: return "Send To Factory"
//         case ScanRecieveFromFactory: return "Recieve From Factory"
//         case ScanSendToStore: return "Send To Store"
//         case ScanRecieveFromStore: return "Recieve From Store"
//         case ScanInfo: return "Scan Info"
//         case ClientReports: return "Client Reports"
//         case OrderReports: return "Order Reports"
//         case ProductReports: return "Product Reports"
//         case StockReports: return "Stock Reports"
//         case ProductSettings: return "ProductSettings"
//         case TemplateSettings: return "TemplateSettings"
//         case LocationSettings: return "LocationSettings"

//     }
// }

// export const Screen_readerValue = () => {
//     switch self {
//         case .NewOrder: return "New Order"
//         case .ProcessOrder: return "Process Order"
//         case .PendingOrders_Store: return "Pending Orders"
//         case .SendOrders_Store: return "Send Orders"
//         case .RecieveOrders_Store: return "Recieve Orders"
//         case .OrdersRecieved_Factory: return "Confirm Orders"
//         case .CompleteOrders_Factory: return "Complete Orders"
//         case .SendOrders_Factory: return "Send Orders"
//         case .CustomerDealing: return "Client Window"
//         case .ApproveTrial: return "Approve Trial"
//         case .ApproveFinishing: return "Approve Alteration"
//         case .ApproveDelivery: return "Deliver Products"
//         case .ViewStatus: return "View Status"
//         case .Search_Store: return "Store Search"  // Changed from Store Reports
//         case .Search_Factory: return "Factory Search" // Changed form Factory Reports
//         case .AllClients: return "All Clients"
//         case .Search_Clients: return "Client Search"
//         case .StockTypes: return "Stock Types"
//         case .Search_Stocks: return "Stock Search"
//         case .Positions: return "Positions"
//         case .Users: return "Users"
//         case .Craftsmen: return "Craftsmen"
//         case .Locations: return "Locations"
//         case .Challans: return "Challans"
//         case .ScanSendToFactory: return "Scan Send To Factory"
//         case .ScanRecieveFromFactory: return "Recieve From Factory"
//         case .ScanSendToStore: return "Scan Send To Store"
//         case .ScanRecieveFromStore: return "Recieve From Store"
//         case .ScanInfo: return "Scan Info"
//         case .ClientReports: return "Client Reports"
//         case .OrderReports: return "Order Reports"
//         case .ProductReports: return "Product Reports"
//         case .StockReports: return "Stock Reports"
//         case .ProductSettings: return "ProductSettings"
//         case .TemplateSettings: return "TemplateSettings"
//         case .LocationSettings: return "LocationSettings"
//     }
// }


export const Screen_moveActionTitle = (scr) => {
    switch (scr) {
        case screen.SendOrders_Store: return "Send Factory"
        case screen.RecieveOrders_Store: return "Recieve Product"
        case screen.OrdersRecieved_Factory: return "Confirm Request"
        case screen.CompleteOrders_Factory: return "Work Completed"
        case screen.SendOrders_Factory: return "Send Store"
        case screen.ApproveTrial: return " Approve?"
        case screen.ApproveFinishing: return " Finished?"
        case screen.ApproveDelivery: return "Deliver"
        case screen.ScanSendToFactory: return "Send To Factory"
        case screen.ScanRecieveFromFactory: return "Recieve From Factory"
        case screen.ScanSendToStore: return "Send To Store"
        case screen.ScanRecieveFromStore: return "Recieve From Store"
        default: return null
    }
}

export const Screen_productsTitle = (scr) => {
    switch (scr) {
        case screen.SendOrders_Store: return "Product (not sent to factory)"
        case screen.RecieveOrders_Store: return "Product (not recieved from factory)"
        case screen.OrdersRecieved_Factory: return "Product (not recieved from store)"
        case screen.CompleteOrders_Factory: return "Product (to complete at factory)"
        case screen.SendOrders_Factory: return "Product (to send at store)"
        case screen.ApproveTrial: return "Product (to approve trial)"
        case screen.ApproveFinishing: return "Product (to approve alteration)"
        case screen.ApproveDelivery: return "Product (to approve delivery)"
        case screen.ScanSendToFactory: return "Product (Scan-Sent to factory)"
        case screen.ScanRecieveFromFactory: return "Product (Scan-Recieved from factory)"
        case screen.ScanSendToStore: return "Product (Scan-Sent to store)"
        case screen.ScanRecieveFromStore: return "Product (Scan-Recieved from store)"
        default: return null
    }
}


export const Screen_isProductDeletionAllowedOn = (scr) => {
    switch (scr) {
        case screen.NewOrder: return true //
        case screen.PendingOrders_Store: return true //Only screen
        case screen.AllClients: return true //
        default: return false
    }
}

export const Screen_isBulkAllowedOn = (scr) => {
    switch (scr) {
        case screen.SendOrders_Store: return true //Only screen
        case screen.RecieveOrders_Store: return true //
        case screen.ApproveTrial: return true
        case screen.ApproveFinishing: return true
        case screen.ApproveDelivery: return true
        case screen.OrdersRecieved_Factory: return true
        case screen.CompleteOrders_Factory: return true
        case screen.SendOrders_Factory: return true
        default: return false
    }
}



// export const Screen_vc = () => {
//     var thisVCId = ""

//     switch self {
//         case .NewOrder: thisVCId = "clientDetailsVC"
//         case .ProcessOrder: thisVCId = "processOrder"
//         case .PendingOrders_Store: thisVCId = "processOrder"
//         case .SendOrders_Store: thisVCId = "processOrder"
//         case .RecieveOrders_Store: thisVCId = "processOrder"
//         case .OrdersRecieved_Factory: thisVCId = "factoryOrders"
//         case .CompleteOrders_Factory: thisVCId = "factoryOrders"
//         case .SendOrders_Factory: thisVCId = "factoryOrders"
//         case .CustomerDealing: thisVCId = "processOrder"
//         case .ApproveTrial: thisVCId = "processOrder"
//         case .ApproveFinishing: thisVCId = "processOrder"
//         case .ApproveDelivery: thisVCId = "processOrder"
//         case .ViewStatus: thisVCId = "orderSearch"
//         case .Search_Store: thisVCId = "orderSearch"
//         case .Search_Factory: thisVCId = "factorySearch"
//         case .AllClients: thisVCId = "clientAllClients"
//         case .Search_Clients: thisVCId = "clientSearch"

//         case .StockTypes: thisVCId = "stockTypes"
//         case .Search_Stocks: thisVCId = "stockSearch"
//         case .Positions: thisVCId = "userPositions"
//         case .Users: thisVCId = "userUsers"
//         case .Craftsmen: thisVCId = "craftsmenVC"
//         case .Locations: thisVCId = "locationsVC"
//         case .Challans: thisVCId = "challanVC"
//         case .ScanSendToFactory: thisVCId = "scanSendVC"
//         case .ScanRecieveFromFactory: thisVCId = "scanSendVC"
//         case .ScanSendToStore: thisVCId = "scanSendVC"
//         case .ScanRecieveFromStore: thisVCId = "scanSendVC"
//         case .ScanInfo: thisVCId = "scanSendVC"
//         case .ClientReports: thisVCId = "clientReportsVC"
//         case .OrderReports: thisVCId = "orderReportsVC"
//         case .ProductReports: thisVCId = "productReportsVC"
//         case .StockReports: thisVCId = "stockReportsVC"
//         case .ProductSettings: thisVCId = "productSettingsVC"
//         case .TemplateSettings: thisVCId = "templateSettingsVC"
//         case .LocationSettings: thisVCId = "locationSettingsVC"

//             if let thisVC = storyboard.instantiateViewController(withIdentifier: thisVCId) as ?UIViewController {
//                 return thisVC
//             }

//             return null
//     }

export const FitPreference = {
    regular: "Regular",
    slim: "Slim",
    comfort: "Comfort"
}


export const PrintSheetType = {
    orderSheet: "Order Sheet",
    productSheet: "Product Sheet"
}

export const CustomerCallResultType = {
    pics: "pics",
    measurements: "measurements",
}

export const ProductCallResultType = {
    pics: "pics",
    measurements: "measurements",
    remarks: "remarks",
}



export const MeasurementType = {
    body: "Body",
    product: "Product",
    conciergeOrder: "conciergeOrder",
    conciergeBody: "conciergeBody"  // Concierge Customer
}

export const MeasurementType_allValues = () => {
    var types = []
    types.push(MeasurementType.body)
    types.push(MeasurementType.product)
    return types
}



export const MeasurementType_readerValue = (mt) => {
    switch (mt) {
        case MeasurementType.body: return "Body"
        case MeasurementType.product: return "Product"
        default: break
    }
}


export const MeasurementUnits = {
    inches: "Inches",
    centimetres: "Centimetres",
    metres: "Metres"
}

export const MeasurementUnits_allValues = () => {
    var mUnits = []
    mUnits.push(MeasurementUnits.inches)
    mUnits.push(MeasurementUnits.centimetres)
    mUnits.push(MeasurementUnits.metres)
    return mUnits
}


export const MeasurementUnits_readerValue = (mu) => {
    switch (mu) {
        case MeasurementUnits.inches: return "Inches"
        case MeasurementUnits.centimetres: return "Centimetres"
        case MeasurementUnits.metres: return "Metres"
        default: break
    }
}



export const StockUnit = {
    inches: "Inches",
    centimetres: "Centimetres",
    metres: "Metres",
    pieces: "Pieces",
    dozens: "Dozens"
}

export const StockUnit_allValues = () => {
    var stockUnits = []
    stockUnits.push(StockUnit.inches)
    stockUnits.push(StockUnit.centimetres)
    stockUnits.push(StockUnit.metres)
    stockUnits.push(StockUnit.pieces)
    stockUnits.push(StockUnit.dozens)
    return stockUnits
}



export const StockUnit_readerValue = (su) => {
    switch (su) {
        case StockUnit.inches: return "Inches"
        case StockUnit.centimetres: return "Centimetres"
        case StockUnit.metres: return "Metres"
        case StockUnit.pieces: return "Pieces"
        case StockUnit.dozens: return "Dozens"
        default: break
    }
}




export const StockSearchKey = {
    stockName: "Stock Name",
    stockCompany: "Company Name",
    stockCode: "Stock Code",
    priceCode: "Price Code"
}

export const StockSearchKey_allValues = () => {
    var searchkeys = []
    searchkeys.push(StockSearchKey.stockName)
    searchkeys.push(StockSearchKey.stockCompany)
    searchkeys.push(StockSearchKey.stockCode)
    searchkeys.push(StockSearchKey.priceCode)
    return searchkeys
}



export const StockSearchKey_readerValue = (sk) => {
    switch (sk) {
        case StockSearchKey.stockName: return "Stock Name"
        case StockSearchKey.stockCompany: return "Company Name"
        case StockSearchKey.stockCode: return "Stock Code"
        case StockSearchKey.priceCode: return "Price Code"
        default: break
    }
}



export const SearchKey = {
    orderId: "Order Id",
    clientPhone: "Client Phone",
    bookingDate: "Booking Date"
    //    case clientName = "Client Name"
}

export const SearchKey_allValues = () => {
    var searchkeys = []
    searchkeys.push(SearchKey.orderId)
    searchkeys.push(SearchKey.clientPhone)
    searchkeys.push(SearchKey.bookingDate)
    //        searchkeys.append(SearchKey.clientName.rawValue)
    return searchkeys
}



export const SearchKey_readerValue = (sk) => {
    switch (sk) {
        case SearchKey.orderId: return "Order Id"
        case SearchKey.clientPhone: return "Client Phone"
        case SearchKey.bookingDate: return "Booking Date"
        default: return null
    }
}


export const ClientSearchKey = {
    clientPhone: "Client Phone",
    clientEmail: "Client Email",
    //    case clientDOB = "Client DateOfBirth"
    clientName: "Client Name"
}


export const ClientSearchKey_allValues = () => {
    var searchkeys = []
    searchkeys.push(ClientSearchKey.clientPhone)
    searchkeys.push(ClientSearchKey.clientEmail)
    //        searchkeys.append(self.clientDOB.rawValue)
    searchkeys.push(ClientSearchKey.clientName)
    return searchkeys
}



export const ClientSearchKey_readerValue = (ck) => {
    switch (ck) {
        case ClientSearchKey.clientPhone: return "Client Phone"
        case ClientSearchKey.clientEmail: return "Client Email"
        //        case .clientDOB : return "Client DateOfBirth"
        case ClientSearchKey.clientName: return "Client Name"
        default: break
    }
}


