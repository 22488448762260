import React from 'react';

import p_clientLogo from "../assets/clientLogo.png"
import p_clientLogoMenu from "../assets/clientLogoMenu.png"
import p_clientLogo512 from "../assets/clientLogo512.png"



// Parse.initialize("YOUR_APP_ID", "YOUR_JAVASCRIPT_KEY");
export const clientLogo = `${p_clientLogo}`
export const clientLogoMenu = `${p_clientLogoMenu}`
export const clientLogo512 = `${p_clientLogo512}`


// // TW-MasterMain-Test
// export const backAppId = "saroVkebp66hXsaCJk9gp0cia0ZyPjAggYmssUTi"
// export const backJSId = "h3yZv1fELxFFTbOjNUZf6Me4LUnZ3Sn7xQKhKWcz"
// export const backMasterKey = "Cr3vbJcSs0NMRpHmhOmInIDZzSPeutsPT9xay0zx"


// TW-3-KaranMoin
export const backAppId = "GrqSyyyearKdGFRJVkayaZ2QmCr2fMiuoybRoC3B"
export const backJSId = "W6fVQy84bw8BJxH1aSLPGuPQzcf9JD7GNaSmjxyJ"

// TW-3-TWBETA
// export const backAppId = "0X4UAGcZNp1vWK6clOiokylwf6wWz11bzzmGME7c"
// export const backJSId = "kqyaRigg8on4JSrjhKejD7asDZbagdw9lA2KVVII"

// export const backMasterKey = "Cr3vbJcSs0NMRpHmhOmInIDZzSPeutsPT9xay0zx"



// // CAVALIER
// export const backAppId = "186DoiyjKt4YSYNni6FjfsWxBsgJjeYkh7DqOH6R"
// export const backJSId = "7z9zVK5CR4dJpbQX4m9MrA7NEt2cr3eyIbRyQ05k"
// export const backMasterKey = "7mo3WKk1iZj5CokWsxgMsRlIyHz7xPQ7BHhzlK4y"

// export const hasShop = false

export const defaultStoreObjid = "FC237G0HJG"  // Main Store
export const defaultUserObjid = "hywLGNUCi6"  // ROY

// export const standardDeliveryTime = "Standard Delivery Time of 2-3 weeks"
// export const freeShippingThreshold = 300
// export const standardPaidShippingPrice = 35


// FOR CONCIERGE
export const awsBaseUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/tw-main/"
export const clientName = "KARAN MOIN"

export const clientPhone = "+91-09999999999999"
export const clientEmail = "info@karanmoin.com"
export const clientEmailFromId = "no-reply@tailorwise.com"  //TW no-reply-email or client validated email with us
export const clientCCEmail = ""


export const hasFactory = true
export const isSingleLocation = false
export const hasAgents = false

// export const clientEmail = "appsquare.in@gmail.com"
// export const clientEmailFromForOrder = "appsquare.in@gmail.com"



export const clientWebsite = "https://www.karanmoin.com"
export const clientTermsAndConditionsUrl = "https://www.tailorwise.com/"



// KM https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/karanmoin/twLogoHome-black.png
// export const clientLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/tw-mastermain/tw-logo-140x30.svg"
// export const clientMenuLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/tw-mastermain/tw-logo-140x30.svg"

export const clientLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/karanmoin/twLogoHome-black.png"
export const clientMenuLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/karanmoin/kmlogo-menu-140x30.png"

export const currencySymbol = "₹"
export const currencyCode = "INR"
export const clientBGHome = ""
export const clientBGHero = ""


export const client_defaultCountry = 'IN (+91)'
export const client_defaultCountryOption = () => {
    return (
        <option value={client_defaultCountry} >{client_defaultCountry}</option>

    )
}

export const client_favCountryList = () => {

    // FIRST OPTION SHOULD BE SAME AS DEFAULT COUNTRY

    return (
        <>
            {client_defaultCountryOption()}
            <option value='US (+1)'  >US (+1)</option>
            <option value='CA (+1)'  >CA (+1)</option>
            <option value='AU (+61)'  >AU (+61)</option>
            <option value='GB (+44)'  >GB (+44)</option>
            <option value='AE (+971)'  >AE (+971)</option>
            <option value='ZW (+263)'  >ZW (+263)</option>
            <option value='ZA (+27)' >ZA (+27)</option>
        </>
    )

}
